<template>
  <div class="content-wrapper">
    <h1>Testing Page</h1>
  </div>
</template>

<script>
export default {
  name: "TestTry",
};
</script>
